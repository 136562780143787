import { createSvgIcon } from '@mui/material/utils'

const Contact = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="33.7 30.9 78.1 71.6">
		<path d="M85.7 75c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2ZM93.8 71c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2ZM77.6 75c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2ZM93.8 79H77.6c-1.1 0-2 .9-2 2s.9 2 2 2h16.2c1.1 0 2-.9 2-2s-.9-2-2-2ZM93.8 87.1H77.6c-1.1 0-2 .9-2 2s.9 2 2 2h16.2c1.1 0 2-.9 2-2s-.9-2-2-2ZM62.5 75.6c2-1.8 3.2-4.3 3.2-7.2 0-5.3-4.3-9.7-9.7-9.7s-9.7 4.3-9.7 9.7 1.2 5.4 3.2 7.2c-1.1.6-2.2 1.4-3 2.4-4.1 4.7-3 12-3 12.3.2 1.1 1.1 1.9 2.2 1.9h20.5c1.1 0 2.1-.8 2.2-1.9 0-.3 1.1-7.6-3-12.3-.9-1-1.9-1.8-3-2.4ZM56 63.2c2.9 0 5.2 2.3 5.2 5.2s-2.3 5.2-5.2 5.2-5.2-2.3-5.2-5.2 2.3-5.2 5.2-5.2Zm-8.1 24.5c0-1.9.4-4.8 2.1-6.8 1.3-1.5 3.2-2.2 5.8-2.2h.5c2.6 0 4.5.7 5.8 2.2 1.7 2 2.1 4.9 2.1 6.8H47.9Z"></path>{' '}
		<path d="M100.8 49H39.3c-3.1 0-5.6 2.5-5.6 5.6v42.3c0 3.1 2.5 5.6 5.6 5.6h61.5c3.1 0 5.6-2.5 5.6-5.6V54.6c0-3.1-2.5-5.6-5.6-5.6Zm1.6 48c0 .9-.7 1.6-1.6 1.6H39.3c-.9 0-1.6-.7-1.6-1.6V54.7c0-.9.7-1.6 1.6-1.6h61.5c.9 0 1.6.7 1.6 1.6V97Z"></path>{' '}
		<circle cx="93.8" cy="48.9" r="18" fill="white"></circle>{' '}
		<path
			d="M91.5 56.7c-.6 0-1.2-.2-1.6-.7l-4.8-4.8c-.9-.9-.9-2.3 0-3.2.9-.9 2.3-.9 3.2 0l3.2 3.2 8.8-8.8c.9-.9 2.3-.9 3.2 0 .9.9.9 2.3 0 3.2L93.1 56c-.4.4-1 .7-1.6.7Z"
			fill="#1d1c2f"
		></path>
	</svg>,
	'Contact'
)

export default Contact
