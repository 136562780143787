import { useMemo } from 'react'

import { useCurrentPractice } from 'contexts/PracticeContext'
import { Banner, BannerVariant } from 'components/common/banner'
import { usePersonaModal } from 'hooks/usePersonaModal'
import Contact from 'icons/Contact'

import { shouldShow1099InfoBanner } from '../helpers'
import { PERSONA_PROCESS_TYPES } from '../../../../constants'

export const Practice1099InfoRequiredBanner = () => {
	const processType = PERSONA_PROCESS_TYPES['1099_INFO']
	const { currentPracticeObject, setCurrentPracticeObject } = useCurrentPractice()

	const show1099InfoBanner = useMemo(
		() => shouldShow1099InfoBanner(currentPracticeObject),
		[currentPracticeObject]
	)

	const { isLoading, handleShowPersonaModal } = usePersonaModal({
		processType: PERSONA_PROCESS_TYPES[processType],
		onComplete: () => {
			// TODO: improve this. Maybe refetching the context?
			setCurrentPracticeObject({
				...currentPracticeObject,
				consolidatedPractice: {
					...currentPracticeObject?.consolidatedPractice,
					status: {
						...currentPracticeObject?.consolidatedPractice?.status,
						[processType]: {
							...currentPracticeObject?.consolidatedPractice?.status?.[processType],
							status: 'completed'
						}
					}
				}
			})
		}
	})

	const showPersonaModal = async () => {
		if (!currentPracticeObject?.dbid) {
			console.warn('currentPracticeObject.dbid is not set')
			return
		}
		handleShowPersonaModal(currentPracticeObject.dbid, true)
	}

	if (!show1099InfoBanner) {
		return null
	}

	return (
		<Banner
			customIcon={<Contact fontSize="medium" sx={{ mr: 1 }} />}
			tooltip="Action Required: Update Your Tax Info for IRS Compliance"
			title="Action Required: Update Your Tax Info for IRS Compliance"
			subtitle="To ensure timely filing of your IRS Form 1099-K, submit your latest W9 information by 12/13/2024."
			buttonTitle={!isLoading ? 'Update' : 'Processing...'}
			variant={BannerVariant.ERROR}
			disabled={isLoading}
			onButtonClick={showPersonaModal}
			showCloseButton={false}
			showButton
		/>
	)
}
