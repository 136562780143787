import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container } from '@mui/material'

import useSettings from 'hooks/useSettings'
import PracticeStripeRequiredInfoBanner from './banners/PracticeStripeRequiredInfoBanner'
import { PracticeStripeFutureRequiredInfoBanner } from './banners/PracticeStripeFutureRequiredInfoBanner'
import { Practice1099InfoRequiredBanner } from './banners/Practice1099InfoRequiredBanner'

export const PracticeBanners = () => {
	const location = useLocation()
	const { settings } = useSettings()

	/**
	 * Hide the Stripe Requirements banner
	 * when the URL is for financing or communications
	 * or when the URL is the account required info page
	 */
	const shouldHideStripeRequirements = useMemo(
		() =>
			location.pathname.startsWith('/financing') ||
			location.pathname.startsWith('/communication') ||
			[
				'/dashboard/chat/new',
				'/dashboard/account/practice-required-info',
				'/settings/required-info'
			].includes(location.pathname),
		[location.pathname]
	)

	return (
		<Container maxWidth={settings.compact ? 'xl' : false}>
			<Box
				display="flex"
				flexDirection="column"
				gap={1}
				sx={{
					'> div:first-child': {
						marginTop: 6
					},
					'> div:last-child': {
						marginBottom: 1
					}
				}}
			>
				<Practice1099InfoRequiredBanner />
				{!shouldHideStripeRequirements && (
					<>
						<PracticeStripeRequiredInfoBanner />
						<PracticeStripeFutureRequiredInfoBanner />
					</>
				)}
			</Box>
		</Container>
	)
}
