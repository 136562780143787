import { makeStyles } from 'tss-react/mui'
import { BannerVariant } from './banner.d'

export const useBannerStyles = makeStyles()({
	container: {
		color: '#1D1D30',
		margin: '0',
		borderRadius: '5px'
	},
	title: {
		fontWeight: 'bold',
		fontSize: '0.85rem',
		margin: 0
	},
	button: {
		borderRadius: 8,
		fontSize: '0.8rem',
		fontWeight: 'bold',
		minWidth: '105px',
		padding: '5px 12px',
		textAlign: 'center',
		textDecoration: 'none'
	},
	actionBox: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		gap: 10
	},
	closeButton: {
		color: 'inherit'
	},
	[BannerVariant.ERROR]: {
		backgroundColor: '#FFE4ED',
		color: 'inherit',
		borderRadius: 5,
		paddingTop: 0,
		paddingBottom: 0
	},
	[BannerVariant.WARN]: {
		backgroundColor: '#FFF2D6',
		borderRadius: 5,
		color: 'inherit',
		paddingTop: 0,
		paddingBottom: 0
	},
	[BannerVariant.INFO]: {
		backgroundColor: '#DCE1FF',
		borderRadius: 5,
		color: 'inherit',
		alignItems: 'center',
		paddingTop: 0,
		paddingBottom: 0
	},
	[`${BannerVariant.ERROR}-btn`]: {
		backgroundColor: '#FFC1D0',
		color: '#DB0035'
	},
	[`${BannerVariant.WARN}-btn`]: {
		backgroundColor: '#FFE2A3',
		color: '#E25203'
	},
	[`${BannerVariant.INFO}-btn`]: {
		backgroundColor: '#CDD3FF',
		color: '#5266FD'
	}
})
