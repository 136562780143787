import axiosClient from './base'

const RESUME_PERSONA_INQUIRY = (unifiedPracticeId: string) =>
	`/api/practices/${unifiedPracticeId}/persona-inquiry/resume`

export const resumePersonaInquiry = (
	unifiedPracticeId: string,
	templateId: string
): Promise<{ data: IPersonaInquiry }> =>
	axiosClient.get(RESUME_PERSONA_INQUIRY(unifiedPracticeId), {
		params: {
			templateId
		}
	})
