import { useState } from 'react'
import { Client } from 'persona'

import { resumePersonaInquiry } from 'apis/persona-inquiry'
import { getPersonaTemplate } from 'apis/persona-template'
import { PERSONA_CONFIG } from '../config'
import { PERSONA_PROCESS_TYPES } from '../constants'

type usePersonaModalProps = {
	processType: PERSONA_PROCESS_TYPES
	onComplete?: () => void
}

export const usePersonaModal = ({ processType, onComplete }: usePersonaModalProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [inquiryCompleted, setInquiryCompleted] = useState<boolean>(false)

	const [inquiry, setInquiry] = useState<IPersonaInquiry | undefined>()
	const [currentTemplate, setCurrentTemplate] = useState<IPersonaTemplate | null>(null)
	const [currentPracticeId, setCurrentPracticeId] = useState<string | null>(null)

	const loadTemplate = async (practiceId: string): Promise<IPersonaTemplate | null> => {
		if (currentPracticeId === practiceId && currentTemplate) {
			return currentTemplate
		}
		try {
			const { data } = await getPersonaTemplate(practiceId, processType)
			const template = data as IPersonaTemplate
			setCurrentTemplate(template)
			setCurrentPracticeId(practiceId)
			return template
		} catch (error) {
			console.error('Failed to load persona template:', error)
			return null
		}
	}

	const resumeInquiry = async (
		practiceId: string,
		templateId: string
	): Promise<IPersonaInquiry | undefined> => {
		try {
			const { data } = await resumePersonaInquiry(practiceId, templateId)
			return data as IPersonaInquiry
		} catch (error) {
			console.warn('No existing inquiry to resume:', error)
			return undefined
		}
	}

	const openPersonaModal = async (template: IPersonaTemplate, personaInquiry?: IPersonaInquiry) => {
		const client = new Client({
			referenceId: template.consolidatedPracticeId,
			environment: PERSONA_CONFIG.environment as 'sandbox' | 'production',
			...(personaInquiry && {
				inquiryId: personaInquiry.inquiryId,
				sessionToken: personaInquiry.sessionToken
			}),
			...(!personaInquiry && {
				templateId: template.templateId
			}),
			onReady: () => {
				client.open()
				setIsLoading(false)
			},
			onComplete: (data) => {
				setInquiry({ inquiryId: data.inquiryId })
				setInquiryCompleted(true)
				onComplete && onComplete()
			},
			onCancel: (data) => {
				setInquiry({
					inquiryId: data.inquiryId,
					sessionToken: data.sessionToken
				})
			}
		})
	}

	const handleShowPersonaModal = async (
		practiceId: string,
		shouldResumeInquiry: boolean = true
	) => {
		setIsLoading(true)

		try {
			const template = await loadTemplate(practiceId)
			if (!template) {
				console.warn('No template available for this practice ID')
				setIsLoading(false)
				return
			}

			let personaInquiry: IPersonaInquiry | undefined = inquiry
			if (shouldResumeInquiry && !personaInquiry) {
				personaInquiry = await resumeInquiry(practiceId, template.templateId)
				if (personaInquiry) setInquiry(personaInquiry)
			}

			openPersonaModal(template, personaInquiry)
		} catch (error) {
			console.error('Failed to open Persona modal:', error)
			setIsLoading(false)
		}
	}

	return {
		inquiry,
		inquiryCompleted,
		handleShowPersonaModal,
		isLoading
	}
}
