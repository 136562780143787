import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { createStripeAccountLink } from 'apis'
import { useCurrentPractice } from 'contexts/PracticeContext'
import errorToast from 'util/errorToast'
import { STRIPE_ACCOUNT_LINK_RETURN_URL, STRIPE_ACCOUNT_LINK_REFRESH_URL } from 'util/stripe'
import { formatUnixDate } from 'util/date'
import { selectContext, selectUser } from 'selectors/auth'
import { useSelector } from 'react-redux'
import { Banner, BannerVariant } from 'components/common/banner'
import {
	hasPermissionForUpdatingStripeRequirements,
	shouldShowPracticeFutureRequirements
} from '../helpers'

export const PracticeStripeFutureRequiredInfoBanner = () => {
	const user = useSelector(selectUser)
	const context = useSelector(selectContext)
	const { currentPracticeObject } = useCurrentPractice()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const showFutureRequirements = useMemo(
		() => shouldShowPracticeFutureRequirements(currentPracticeObject, user),
		[currentPracticeObject, user]
	)

	const requiredInfo = useMemo(() => {
		const requirements = currentPracticeObject?.additionalStripeFutureRequirements?.currently_due
			?.length
			? currentPracticeObject?.additionalStripeFutureRequirements?.currently_due
			: currentPracticeObject?.additionalStripeFutureRequirements?.eventually_due
		if (!requirements) return ''
		const tx = requirements.slice(0, 3).join(',')
		if (requirements.length > 3) return `${tx},...`
		return tx
	}, [
		currentPracticeObject?.additionalStripeFutureRequirements?.currently_due,
		currentPracticeObject?.additionalStripeFutureRequirements?.eventually_due
	])

	const deadline = useMemo(
		() =>
			currentPracticeObject?.additionalStripeFutureRequirements?.current_deadline &&
			formatUnixDate(
				currentPracticeObject.additionalStripeFutureRequirements.current_deadline,
				'MM/dd/yy'
			),
		[currentPracticeObject?.additionalStripeFutureRequirements?.current_deadline]
	)

	const userCanUpdateRequirements = useMemo(
		() => hasPermissionForUpdatingStripeRequirements(context, currentPracticeObject),
		[context, currentPracticeObject]
	)

	const bannerSubtitle = useMemo(
		() =>
			userCanUpdateRequirements
				? `Please update by ${deadline}, or your ability to accept payments may be impacted.`
				: `Please prompt the practice manager to log in and update by ${deadline}, or your ability to accept payments may be impacted`,
		[userCanUpdateRequirements, deadline]
	)

	const redirectToStripe = useCallback(async () => {
		setIsLoading(true)
		try {
			const createAccountLinkResult = await createStripeAccountLink(
				currentPracticeObject.stripeAccountId,
				{
					collect: 'eventually_due',
					futureRequirements: 'include',
					refreshURL: STRIPE_ACCOUNT_LINK_REFRESH_URL,
					returnURL: STRIPE_ACCOUNT_LINK_RETURN_URL
				}
			)
			toast.success('Opening a Secure Link...')
			window.open(createAccountLinkResult.data.url, '_blank')
		} catch (error) {
			errorToast(error)
		}
		setIsLoading(false)
	}, [currentPracticeObject])

	if (!showFutureRequirements || !requiredInfo) {
		return null
	}

	return (
		<Banner
			tooltip={`Missing Info: ${requiredInfo}`}
			title="Action Needed: Update your account information"
			subtitle={bannerSubtitle}
			buttonTitle={!isLoading ? 'Update' : 'Redirecting...'}
			onButtonClick={redirectToStripe}
			variant={BannerVariant.WARN}
			showButton={userCanUpdateRequirements}
			disabled={isLoading}
			showCloseButton={false}
		/>
	)
}
