import axiosClient from './base'

const GET_PERSONA_TEMPLATE = (unifiedPracticeId: string, processType: string) =>
	`/api/practices/${unifiedPracticeId}/persona-template/${processType}`

export const getPersonaTemplate = (
	unifiedPracticeId: string,
	processType: string
): Promise<{
	data: {
		consolidatedPracticeId: string
		templateId: string
	}
}> => axiosClient.get(GET_PERSONA_TEMPLATE(unifiedPracticeId, processType))
